._5i8woXJEbEGKKUzeCZ1WY {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  color: #131311;
  font-size: 20px; }
  @media screen and (max-width: 767px) {
    ._5i8woXJEbEGKKUzeCZ1WY {
      display: flex; } }

._1P4WZQ4TwtKllbUHloey7Z {
  position: relative;
  padding-top: 17px;
  padding-bottom: 17px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  background-color: #f8f8f8; }

._3YErnIkdVJSux96itP5ZAt {
  padding: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 15px;
  background: none;
  border: none;
  outline: none;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }
  ._3YErnIkdVJSux96itP5ZAt > svg {
    width: 12px;
    height: 19px; }

._3uRnt3X2y5P2_Glvd_RSLf {
  padding: 0;
  margin: 0;
  list-style-type: none; }

._2PEJAq0cnCQcv5kzdhGGJ4 {
  padding: 19px 15px 26px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  border: none;
  border-bottom: 1px solid #dadada;
  background: none;
  outline: none; }
  ._2PEJAq0cnCQcv5kzdhGGJ4:hover {
    color: currentColor;
    text-decoration: none; }
  ._2PEJAq0cnCQcv5kzdhGGJ4 > svg {
    width: 12px;
    height: 19px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
    ._2PEJAq0cnCQcv5kzdhGGJ4 > svg > path {
      stroke-width: 1px; }

.m2GQmWmAEBP2huJ2icv-6 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

._2HQLSpAijOCP98oFsmm1jp {
  padding: 0 15px 40px; }

._347LeEgL3DQIyydrKnuU0g {
  width: 100%;
  margin-bottom: 18px;
  text-transform: uppercase; }
  ._347LeEgL3DQIyydrKnuU0g:last-child {
    margin-bottom: 0; }

.bMmphbqvUOmH0BEprOB8Q {
  flex-grow: 1;
  padding: 3px 15px 0; }

._3yX2FLRdEp3QBY6gokzgyi {
  margin-top: 28px; }
