._2PeNZqrfLBo3R0-ktrS7Yb {
  padding: 9px 21px;
  border: none;
  outline: none;
  background-color: #9b9b9b;
  color: #f8f8f8;
  font-size: 18px;
  line-height: 1.15;
  border-radius: 4px; }
  @media screen and (max-width: 767px) {
    ._2PeNZqrfLBo3R0-ktrS7Yb {
      padding: 12px 22px; } }
  ._2PeNZqrfLBo3R0-ktrS7Yb[disabled] {
    opacity: 0.7; }

._3pIOSi8maSegzk_q34dmzy {
  background-color: #4f4f4f; }
  ._3pIOSi8maSegzk_q34dmzy[disabled] {
    opacity: 1;
    background-color: #9b9b9b; }

._1rRsv9I-ad8FJ5-rKS6rn- {
  background-color: #d9534f; }
  ._1rRsv9I-ad8FJ5-rKS6rn-:focus:not([disabled]), ._1rRsv9I-ad8FJ5-rKS6rn-:hover:not([disabled]) {
    background-color: #ea6368; }
  ._1rRsv9I-ad8FJ5-rKS6rn-:active:not([disabled]) {
    background-color: #c81920; }

.lmCys1-nnMyYmfys0whbK {
  background-color: #0275d8; }
  .lmCys1-nnMyYmfys0whbK:focus:not([disabled]), .lmCys1-nnMyYmfys0whbK:hover:not([disabled]) {
    background-color: #428bca; }
  .lmCys1-nnMyYmfys0whbK:active:not([disabled]) {
    background-color: #0061b4; }

._3rwvnTe_PsPvBJoDcsJbNP {
  background-color: #5bc0de; }

._2bbV2YQQC6NJMp3OzIA1Mw {
  background-color: #fff;
  color: #131311;
  border: 1px solid #131311; }
  ._2bbV2YQQC6NJMp3OzIA1Mw:focus:not([disabled]), ._2bbV2YQQC6NJMp3OzIA1Mw:hover:not([disabled]) {
    background-color: #dadada; }
  ._2bbV2YQQC6NJMp3OzIA1Mw:active:not([disabled]) {
    background-color: #979797; }

.x89dYHR3uW7Y8tjKpJF1t {
  background-color: #5cb85c; }
  .x89dYHR3uW7Y8tjKpJF1t:focus:not([disabled]), .x89dYHR3uW7Y8tjKpJF1t:hover:not([disabled]) {
    background-color: #7ecb7e; }
  .x89dYHR3uW7Y8tjKpJF1t:active:not([disabled]) {
    background-color: #489448; }

._6_AZQEFDRmwF2slqKrQzH {
  min-width: 170px;
  text-transform: uppercase; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    ._6_AZQEFDRmwF2slqKrQzH {
      min-width: 289px; } }
  @media screen and (max-width: 767px) {
    ._6_AZQEFDRmwF2slqKrQzH {
      min-width: initial;
      min-width: auto; } }

._2HY8WmcCp8L4l4twbltMX- {
  text-align: right; }
  ._2HY8WmcCp8L4l4twbltMX- > button {
    margin-right: 10px; }
    ._2HY8WmcCp8L4l4twbltMX- > button:last-child {
      margin-right: 0; }
