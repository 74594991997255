._3DvUwIBJmNoaJ3cpwLSRwO {
  margin: 0;
  margin-bottom: 12px;
  text-transform: uppercase;
  font-size: 20px;
  color: #131311; }
  @media screen and (max-width: 767px) {
    ._3DvUwIBJmNoaJ3cpwLSRwO {
      margin-bottom: 5px; } }

._1EBqyFsohONOJA0Q9tXMgM {
  position: relative; }
  @media screen and (max-width: 767px) {
    ._1EBqyFsohONOJA0Q9tXMgM {
      margin-bottom: 71px; } }

._24yHmKs3YZP_Sr0KKg5Jye {
  width: 100%; }
  @media screen and (max-width: 767px) {
    ._24yHmKs3YZP_Sr0KKg5Jye {
      display: none; } }

._3WWVPDVZ5VUAfAs8tmaL7n {
  display: none;
  width: 100%;
  border-radius: 30px;
  resize: none;
  min-height: 44px; }
  @media screen and (max-width: 767px) {
    ._3WWVPDVZ5VUAfAs8tmaL7n {
      display: inline-block; } }

._2GAsnU6NcJNMTIfc-cp5sc {
  margin-top: 15px; }
  ._2GAsnU6NcJNMTIfc-cp5sc > svg {
    display: none;
    width: 11px;
    height: 22px; }
    @media screen and (max-width: 767px) {
      ._2GAsnU6NcJNMTIfc-cp5sc > svg {
        display: inline-block; } }
  @media screen and (max-width: 767px) {
    ._2GAsnU6NcJNMTIfc-cp5sc {
      padding: 0;
      width: 44px;
      height: 44px;
      position: absolute;
      bottom: 5px;
      right: 0;
      font-size: 0;
      border-radius: 50%; } }
