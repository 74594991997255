._39ZOq56YK0PPhpeO3vyErN {
  margin-top: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #bdbdbd;
  font-size: 30px;
  font-weight: 300; }
  ._39ZOq56YK0PPhpeO3vyErN > svg {
    width: 61px;
    height: 62px;
    margin-bottom: 21px; }

._2wKr8vSihHvVnw9DLcpKPF {
  margin: 0;
  max-width: 488px;
  text-align: center; }
