._3YRKEq7P56151_5jF9kvsw {
  margin-bottom: 42px;
  display: flex; }
  @media screen and (max-width: 992px) {
    ._3YRKEq7P56151_5jF9kvsw {
      display: block; } }

._1Gso-inzg7yLtBcBuwIplJ {
  display: flex;
  flex-direction: column;
  min-width: 150px; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    ._1Gso-inzg7yLtBcBuwIplJ {
      flex-direction: row;
      justify-content: flex-end;
      width: auto; } }
  @media screen and (max-width: 767px) {
    ._1Gso-inzg7yLtBcBuwIplJ {
      display: none; } }
  ._1Gso-inzg7yLtBcBuwIplJ > button {
    min-width: 135px;
    margin-bottom: 10px; }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      ._1Gso-inzg7yLtBcBuwIplJ > button {
        margin-bottom: 0;
        margin-right: 11px; } }
    ._1Gso-inzg7yLtBcBuwIplJ > button:last-child {
      margin-right: 0; }
