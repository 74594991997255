._3jfxb8G44goFPrQQpaqQ-V {
  flex-grow: 1;
  position: relative;
  margin-right: 25px;
  padding: 25px 22px;
  background: rgba(196, 196, 196, 0.28);
  border: 1px solid #cacaca;
  font-family: "Roboto", sans-serif;
  color: #373a3c; }
  @media screen and (max-width: 767px) {
    ._3jfxb8G44goFPrQQpaqQ-V {
      margin-right: 0; } }

._3QlMPvSbcViYzhVFneKemQ {
  margin: 0;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 24px;
  color: #131311; }
  @media screen and (max-width: 767px) {
    ._3QlMPvSbcViYzhVFneKemQ {
      margin-bottom: 12px;
      font-size: 30px;
      font-weight: 400; } }

._1ppI3szXO5gE_rJKaEN38H {
  margin: 0;
  display: flex;
  font-size: 16px;
  line-height: 1.9; }
  ._1ppI3szXO5gE_rJKaEN38H > dt,
  ._1ppI3szXO5gE_rJKaEN38H > dd {
    display: inline-block;
    font-size: inherit;
    line-height: inherit; }
  ._1ppI3szXO5gE_rJKaEN38H > dt {
    margin-right: 7px;
    min-width: 142px;
    font-weight: 300; }
    @media screen and (max-width: 767px) {
      ._1ppI3szXO5gE_rJKaEN38H > dt {
        width: 105px;
        margin-right: 19px; } }

._32uizunR0oLO75fBAYx-st {
  padding-bottom: 19px;
  border-bottom: 1px solid #cacaca; }

._3iYBnAIzZydQvTIfJTphks {
  padding-top: 15px; }

._1Vz6NCwGOadkQ6hXY4J37r {
  margin-bottom: 10px; }

.baakPAiyh2S_IwJ2RoQeJ {
  padding: 5px;
  min-width: auto;
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 11px;
  line-height: 1.18; }

.MjphKXgAAZXhf9KZQGtio {
  display: none;
  background: none;
  border: none;
  outline: none; }
  .MjphKXgAAZXhf9KZQGtio > svg {
    width: 5px;
    height: 23px; }
  @media screen and (max-width: 767px) {
    .MjphKXgAAZXhf9KZQGtio {
      display: inline-block;
      position: absolute;
      top: 16px;
      right: 11px; } }
