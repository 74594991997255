._285H2Q4bpphewIBI1WBkRQ {
  position: relative;
  margin-bottom: 14px;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 16px; }
  ._285H2Q4bpphewIBI1WBkRQ .Select--multi .Select-value {
    display: none; }
  ._285H2Q4bpphewIBI1WBkRQ .Select-menu-outer {
    z-index: 10; }
  @media screen and (max-width: 767px) {
    ._285H2Q4bpphewIBI1WBkRQ .Select {
      font-size: 18px; }
    ._285H2Q4bpphewIBI1WBkRQ .Select-control {
      height: 44px; }
      ._285H2Q4bpphewIBI1WBkRQ .Select-control > * {
        height: 100%; }
    ._285H2Q4bpphewIBI1WBkRQ .Select-placeholder,
    ._285H2Q4bpphewIBI1WBkRQ .Select-value-label {
      line-height: 44px; } }

._1XUksWtvFHVoTRsWg7FWJl {
  margin-right: 10px;
  margin-top: 6px;
  background-color: rgba(0, 126, 255, 0.08);
  border-radius: 2px;
  border: 1px solid rgba(0, 126, 255, 0.24);
  color: #007eff;
  display: inline-block;
  font-size: 16px;
  line-height: 1.15;
  vertical-align: top; }
  @media screen and (max-width: 767px) {
    ._1XUksWtvFHVoTRsWg7FWJl {
      font-size: 18px; } }

.tVilP2AAQvlqsWktLR-N6 {
  height: 100%;
  padding: 5px 8px;
  display: inline-block;
  cursor: pointer;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-right: 1px solid rgba(0, 126, 255, 0.24); }

._8tYkGl6gvHC21OJNRyIjU {
  padding: 4px 12px 4px 10px;
  display: inline-block;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  cursor: default; }

._2iZrWZJyvJQqjiLdrcD-dn {
  position: absolute;
  color: #aaa;
  top: 8px;
  left: 12px; }
  @media screen and (max-width: 767px) {
    ._2iZrWZJyvJQqjiLdrcD-dn {
      top: 12px; } }
