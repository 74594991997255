.pGmWP45vokNAt-1BVTcmY {
  padding: 4px 6px;
  margin-bottom: 3px;
  display: inline-block;
  min-width: 64px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  background-color: #9b9b9b;
  color: #fff;
  border-radius: 3px; }
  @media screen and (max-width: 767px) {
    .pGmWP45vokNAt-1BVTcmY {
      text-align: left;
      min-width: auto; } }

.N_TyDRhTNPd1Eh5R3wzlS {
  background-color: #d9534f; }

._2GpOCc0FUtc_8_cTVOwxuL {
  background-color: #5cb85c; }

._35E79X7y378MUIk3sZYm3d {
  background-color: #0275d8; }

._1jcht9tvCmvXy6AQHgCiMU {
  background-color: #5bc0de; }
