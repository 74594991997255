.gd6cTVOdsNRLWurXzfih4 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px; }

._2_m4RIpADKCg_n5bRO7Bjk {
  margin-right: 10px; }

._5DZ2_JcVi_B8XKz8oZYes {
  text-align: center; }
