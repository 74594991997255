._3bAADAre5iWFLtLwRAc7Pt {
  padding-top: 8px;
  padding-bottom: 30px;
  margin-bottom: 25px;
  color: #131311;
  border-bottom: 1px solid #ccc; }

._1dLE-fBiUDMnp3kiNq-D77 {
  width: 64px;
  height: 64px; }
  @media screen and (max-width: 767px) {
    ._1dLE-fBiUDMnp3kiNq-D77 {
      width: 50px;
      height: 50px; } }

.RKHCqzo9-R1mhjc5zIwaI {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  width: 16.6666;
  align-self: flex-start; }
  @media screen and (max-width: 767px) {
    .RKHCqzo9-R1mhjc5zIwaI {
      visibility: hidden;
      position: absolute; } }

._2Z8QPqsYTdTg3sjfJ-eBDX {
  margin: 0;
  font-size: 36px;
  font-weight: normal; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    ._2Z8QPqsYTdTg3sjfJ-eBDX {
      font-size: 40px; } }
  @media screen and (max-width: 767px) {
    ._2Z8QPqsYTdTg3sjfJ-eBDX {
      font-size: 30px; } }

._17DlAnwxD10PtxFZ1Qdg2I {
  font-size: 16px;
  font-weight: 300; }
  @media screen and (max-width: 767px) {
    ._17DlAnwxD10PtxFZ1Qdg2I {
      font-size: 18px;
      color: #828282; } }

.iPXOsp12Z7z0kW9nc-6qI { }
  @media screen and (max-width: 767px) {
    .iPXOsp12Z7z0kW9nc-6qI {
      visibility: hidden;
      position: absolute; } }

.kfdrW5bHGBaxhTgwM2gLj {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .kfdrW5bHGBaxhTgwM2gLj {
      margin-bottom: 20px;
      font-size: 18px; } }

._3sBmUnDlUjkaUAhqfYF60P {
  font-size: 18px;
  font-weight: normal; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    ._3sBmUnDlUjkaUAhqfYF60P {
      margin-bottom: 55px;
      font-size: 20px;
      font-weight: 500; } }

._1Rg8n2MpDPu4CrJ3fwzf9S {
  margin-bottom: 10px; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    ._1Rg8n2MpDPu4CrJ3fwzf9S {
      display: inline-block; } }

.yLsJm_-IvWmackc2_XrBa {
  margin-bottom: 30px; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .yLsJm_-IvWmackc2_XrBa {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px; } }
  @media screen and (max-width: 767px) {
    .yLsJm_-IvWmackc2_XrBa {
      margin-bottom: 20px; } }

._15IDlVLfiv3ec31x8qKHf6 {
  width: 100%; }

.zIXkPa-PHYS55G5Vo2YoG {
  display: inline-block;
  padding: 10px 20px;
  color: #0275d8;
  border: 1px solid currentColor;
  border-radius: 3px;
  font-size: 16px; }
  .zIXkPa-PHYS55G5Vo2YoG:hover {
    color: #0275d8;
    text-decoration: none; }
  .zIXkPa-PHYS55G5Vo2YoG > svg {
    margin-left: 5px;
    width: 15px;
    height: 15px;
    vertical-align: middle; }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      .zIXkPa-PHYS55G5Vo2YoG > svg {
        margin-left: 10px;
        width: 19px;
        height: 19px; } }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .zIXkPa-PHYS55G5Vo2YoG {
      margin-top: -0.4em; } }
  @media screen and (max-width: 767px) {
    .zIXkPa-PHYS55G5Vo2YoG {
      display: none; } }

._3ZkpSi9SY9sxDQ14pZpCqq {
  display: none;
  color: #0275d8; }
  ._3ZkpSi9SY9sxDQ14pZpCqq:hover {
    color: #0275d8; }
  ._3ZkpSi9SY9sxDQ14pZpCqq:after {
    content: '\A';
    white-space: pre; }
  @media screen and (max-width: 767px) {
    ._3ZkpSi9SY9sxDQ14pZpCqq {
      display: inline; } }

._1TQJh2DCUvS95sgwzIbqsi:after {
  content: ', '; }

@media screen and (max-width: 767px) {
  ._1TQJh2DCUvS95sgwzIbqsi {
    display: none; } }

._2FqMOVY_CBZKXucQALzbuW {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    ._2FqMOVY_CBZKXucQALzbuW {
      margin-bottom: 30px; } }
  @media screen and (max-width: 767px) {
    ._2FqMOVY_CBZKXucQALzbuW {
      justify-content: flex-start; } }

._1bGNinljzTENfg4ub_WLhP {
  width: 16.6666%; }
  @media screen and (max-width: 767px) {
    ._1bGNinljzTENfg4ub_WLhP {
      width: 70px; } }

._3jsHm33Kt1JgoTPmRY0y_2 {
  flex-grow: 1; }

@media screen and (max-width: 767px) {
  .ulA93Dwa_xKkdLPttoim8 {
    display: flex; } }

@media screen and (max-width: 767px) {
  ._1VkVJfE4fZP2dtYPo2kj0M {
    flex-grow: 1;
    text-transform: uppercase; } }
