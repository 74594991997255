._2N2ozZWcVzFrWrk-S9wJWl {
  flex-grow: 1;
  position: relative;
  padding: 16px 20px 10px;
  margin-bottom: 10px;
  margin-right: 30px;
  background-color: #fff;
  border: 1px solid #cacaca; }
  @media screen and (max-width: 992px) {
    ._2N2ozZWcVzFrWrk-S9wJWl {
      margin-right: 0; } }
  @media screen and (max-width: 767px) {
    ._2N2ozZWcVzFrWrk-S9wJWl {
      padding-right: 30px; } }

._3oZ5GzACU46h8xCv4rv6SP {
  background-color: rgba(196, 196, 196, 0.28); }

._7f-ZyQ0VOJW0X5iWkQNRe {
  border-radius: 2px 2px 0 0; }

._3hPWYRnJl4mv88LKS2c8Kr {
  margin: 0;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 24px;
  color: #131311; }
  @media screen and (max-width: 767px) {
    ._3hPWYRnJl4mv88LKS2c8Kr {
      margin-bottom: 12px;
      font-size: 30px;
      font-weight: 400; } }

.gq5xhA6QrRihXX0aUuXO4 {
  margin: 0; }

._1s_IBRAU2n9_2n-W-0wfEZ {
  margin-bottom: 11px;
  display: flex;
  font-size: 16px;
  color: #131311; }
  @media screen and (max-width: 767px) {
    ._1s_IBRAU2n9_2n-W-0wfEZ {
      margin-bottom: 13px;
      font-size: 18px; } }

.DlMWD59RPFtd4aO853jc8 { }
  @media screen and (max-width: 767px) {
    .DlMWD59RPFtd4aO853jc8 {
      display: none; } }

._2rqp7Lh3LLi_xOkXHGw8uQ {
  display: inline-block;
  margin-right: 7px;
  min-width: 142px;
  font-weight: 300; }
  @media screen and (max-width: 767px) {
    ._2rqp7Lh3LLi_xOkXHGw8uQ {
      width: 105px;
      margin-right: 19px; } }

.DyQRKdzVa8lD0J5Hx18OD {
  display: inline-block; }

._3Lv4_yelJnnjT-9GFTGfZ9 {
  display: flex;
  justify-content: space-between; }

._21y-1N3OsNOP0skFIlkMVp {
  min-width: 142px; }
  @media screen and (max-width: 767px) {
    ._21y-1N3OsNOP0skFIlkMVp {
      display: none; } }

._1A66oxTBJq2bZyl8zejgOA {
  display: none;
  background: none;
  border: none;
  outline: none; }
  ._1A66oxTBJq2bZyl8zejgOA > svg {
    width: 5px;
    height: 23px; }
  @media screen and (max-width: 767px) {
    ._1A66oxTBJq2bZyl8zejgOA {
      display: inline-block;
      position: absolute;
      top: 16px;
      right: 11px; } }

._2UPjf6GRGyVj4oxgSZshRr {
  margin: -16px -20px 16px;
  padding: 16px 20px 10px;
  font-size: 12px;
  font-style: italic;
  background-color: #e6f6fa;
  border-bottom: 1px solid #cacaca;
  color: #131311; }
  @media screen and (max-width: 767px) {
    ._2UPjf6GRGyVj4oxgSZshRr {
      padding-right: -20px; } }
