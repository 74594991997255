._27ZJhSGiEw6LCmZq8kPqZ_ {
  position: relative;
  padding: 10px 10px 25px;
  width: 100%;
  border: none;
  background: none;
  text-align: left;
  border-bottom: 1px solid #e7e7e7;
  cursor: default; }
  ._27ZJhSGiEw6LCmZq8kPqZ_:first-child {
    border-top: 1px solid #e7e7e7; }
  @media screen and (max-width: 992px) {
    ._27ZJhSGiEw6LCmZq8kPqZ_ {
      padding: 5px 15px 14px;
      border: none; }
      ._27ZJhSGiEw6LCmZq8kPqZ_:first-child {
        border: none; } }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    ._27ZJhSGiEw6LCmZq8kPqZ_ {
      margin-bottom: 17px; } }
  @media screen and (max-width: 767px) {
    ._27ZJhSGiEw6LCmZq8kPqZ_ {
      margin-bottom: 15px; }
      ._27ZJhSGiEw6LCmZq8kPqZ_ ._1YuhBTbOnKBKNYNxV042sU {
        display: flex;
        flex-wrap: wrap;
        align-items: center; } }

._3OHYwY6VmKh-y6eDk7gM7_ { }
  @media screen and (max-width: 992px) {
    ._3OHYwY6VmKh-y6eDk7gM7_ {
      background-color: #f0f0f0; } }

@media screen and (min-width: 768px) and (max-width: 992px) {
  ._14W5zPVijRAyp1THvjGbYy {
    width: 417px; } }

@media screen and (max-width: 767px) {
  ._14W5zPVijRAyp1THvjGbYy {
    width: 269px; } }

._1qwTzheI8RGGEl--0mdcz9 { }
  ._1qwTzheI8RGGEl--0mdcz9 ._1KptmdEEjYNvv5-drHvAL1 {
    color: #428bca; }

._66mhDaULsFzKHJU4-OByH { }
  @media screen and (max-width: 992px) {
    ._66mhDaULsFzKHJU4-OByH {
      margin-left: auto; } }
  ._66mhDaULsFzKHJU4-OByH ._1KptmdEEjYNvv5-drHvAL1 {
    color: #5cb85c; }
    @media screen and (max-width: 767px) {
      ._66mhDaULsFzKHJU4-OByH ._1KptmdEEjYNvv5-drHvAL1 {
        margin-right: 0; } }
  @media screen and (max-width: 767px) {
    ._66mhDaULsFzKHJU4-OByH ._30rSQ5AiocqF7gwEYcp6jq {
      margin-right: 9px; } }
  @media screen and (max-width: 767px) {
    ._66mhDaULsFzKHJU4-OByH ._1YuhBTbOnKBKNYNxV042sU {
      flex-direction: row-reverse; } }

._3mkqahYQ_CH8jHtXzw_4N9 {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    ._3mkqahYQ_CH8jHtXzw_4N9 {
      padding-left: 14px;
      margin-bottom: 0; } }
  @media screen and (max-width: 767px) {
    ._3mkqahYQ_CH8jHtXzw_4N9 {
      font-size: 16px;
      margin-bottom: 0; } }

._1KptmdEEjYNvv5-drHvAL1 {
  margin-right: 13px;
  font-weight: 500; }
  @media screen and (max-width: 767px) {
    ._1KptmdEEjYNvv5-drHvAL1 {
      margin-right: 9px; } }

._30rSQ5AiocqF7gwEYcp6jq {
  color: #828282;
  font-weight: 300;
  font-size: 14px; }

.XbP3VfjxUlv0M7zTkhFWP {
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: #131311;
  font-size: 18px;
  line-height: 1.15;
  background: none;
  border: none;
  white-space: pre-line;
  font-family: "Roboto", sans-serif;
  word-break: break-word;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text; }
  @media screen and (max-width: 992px) {
    .XbP3VfjxUlv0M7zTkhFWP {
      padding: 11px 14px;
      font-size: 16px;
      border: 1px solid #e7e7e7;
      background-color: #fff; } }

@media screen and (max-width: 992px) {
  ._2uouWLpitL6syvwUnfyk9z {
    display: none; } }

._3rKqFtmGuC_6qPnSIQiz0u {
  margin-right: 25px;
  padding: 0;
  border: none;
  background: none;
  font-size: 14px; }
  ._3rKqFtmGuC_6qPnSIQiz0u:last-child {
    margin-right: 0; }

.MJRObSyw-89gHx7QnpHeK {
  color: #ed1c24;
  text-transform: uppercase; }

._2FV0mykb6F8gHj7hZN05cE {
  width: 100%;
  margin-bottom: 10px; }
