._1HMoEnFJuNAh7o1drhEGq8 {
  margin-bottom: 10px;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse; }
  ._1HMoEnFJuNAh7o1drhEGq8 th,
  ._1HMoEnFJuNAh7o1drhEGq8 td {
    font-weight: 400;
    border: 1px solid #dadada;
    border-left: none;
    border-top: none; }
    ._1HMoEnFJuNAh7o1drhEGq8 th:last-child,
    ._1HMoEnFJuNAh7o1drhEGq8 td:last-child {
      border-right: none; }
  ._1HMoEnFJuNAh7o1drhEGq8 th {
    padding: 6px 17px;
    font-size: 12px;
    text-transform: uppercase; }
  ._1HMoEnFJuNAh7o1drhEGq8 td {
    padding: 20px 14px;
    font-size: 18px; }
