._1CZh8S-bL_IeTATgI43BiO {
  padding: 14px 5px;
  font-size: 18px;
  line-height: 1.1666;
  border-bottom: 1px solid #dadada; }
  ._1CZh8S-bL_IeTATgI43BiO:hover {
    background-color: rgba(242, 242, 242, 0.52); }
  ._1CZh8S-bL_IeTATgI43BiO:last-child {
    border: none; }

.oGnE_2fs2pwQMG4-rMbHJ {
  color: currentColor; }
  .oGnE_2fs2pwQMG4-rMbHJ:hover {
    color: #428bca; }

.XrbedNMCfb-gWospCTquH {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 12px; }

.sq_3aSgDo1YVZ2OEmczVj,
._3JDWDyEstkIrD3glizsqEu {
  display: inline;
  line-height: 1.75;
  font-weight: 200; }

.sq_3aSgDo1YVZ2OEmczVj {
  color: #828282; }

._3JDWDyEstkIrD3glizsqEu {
  color: #131311; }
