.PbLmsQsSvC4sObB9SqG4N {
  display: none; }
  @media screen and (max-width: 767px) {
    .PbLmsQsSvC4sObB9SqG4N {
      display: block; } }

._2SvJ7ecMiGeS4ifuuOHSiX > ul {
  display: flex;
  border-bottom: 1px solid #dddddd; }

@media screen and (max-width: 767px) {
  ._2SvJ7ecMiGeS4ifuuOHSiX {
    display: none; } }

._2mC8nX6035Us5ll577rwyF {
  flex-grow: 1;
  margin-bottom: 0 !important;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center; }
  ._2mC8nX6035Us5ll577rwyF > a {
    background-color: transparent !important;
    border: none !important; }
  ._2mC8nX6035Us5ll577rwyF.active {
    background-color: #e7e7e7; }
    ._2mC8nX6035Us5ll577rwyF.active ._2CZgqAFz3PGCc4Tgbaxx2h {
      color: #131311; }

._2CZgqAFz3PGCc4Tgbaxx2h {
  margin: 0;
  text-align: center;
  font-size: 18px;
  line-height: 1.15;
  color: #898989; }

.rgGGRDt1Wbw7nFrEbg70A {
  margin-top: 25px;
  margin-bottom: 30px;
  max-width: 256px; }

._1_vzwFWepkdsnmdNkGO-1K {
  display: flex;
  justify-content: space-between; }
