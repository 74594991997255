._-6ceywAp0WG18hCTcdrYW {
  position: relative;
  text-align: left;
  vertical-align: top;
  padding: 18px 20px;
  background-color: rgba(196, 196, 196, 0.28); }
  ._-6ceywAp0WG18hCTcdrYW:after {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    background-color: #ddd;
    left: 0;
    right: 0;
    bottom: 1px; }
  ._-6ceywAp0WG18hCTcdrYW:first-child:after {
    left: 20px; }
  ._-6ceywAp0WG18hCTcdrYW:last-child:after {
    right: 20px; }

.rM2CxHCCAmBoYFlFk602m:last-child > ._-6ceywAp0WG18hCTcdrYW:after {
  display: none; }

.rM2CxHCCAmBoYFlFk602m:last-child > ._-6ceywAp0WG18hCTcdrYW:first-child {
  border-radius: 0 0 0 3px; }

.rM2CxHCCAmBoYFlFk602m:last-child > ._-6ceywAp0WG18hCTcdrYW:last-child {
  border-radius: 0 0 3px 0; }

.rM2CxHCCAmBoYFlFk602m:first-child > ._-6ceywAp0WG18hCTcdrYW:first-child {
  border-radius: 3px 0 0 0; }

.rM2CxHCCAmBoYFlFk602m:first-child > ._-6ceywAp0WG18hCTcdrYW:last-child {
  border-radius: 0 3px 0 0; }

.rM2CxHCCAmBoYFlFk602m:only-child > ._-6ceywAp0WG18hCTcdrYW:first-child {
  border-radius: 3px 0 0 3px; }

.rM2CxHCCAmBoYFlFk602m:only-child > ._-6ceywAp0WG18hCTcdrYW:last-child {
  border-radius: 0 3px 3px 0; }

.X4jcAKLtk47l0bfZ6FnXO {
  font-weight: 500;
  white-space: nowrap; }

._2oGv4S1D4EQlf8ScsFT8rw {
  white-space: nowrap; }
  ._2oGv4S1D4EQlf8ScsFT8rw:after {
    content: ', '; }
  ._2oGv4S1D4EQlf8ScsFT8rw:last-child:after {
    content: ''; }
