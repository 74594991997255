.bqUosqHul5Alw3Wu-lpSq {
  text-align: center; }

._2pz9YGqg5MlsYpTyKFSW8e {
  width: 100%;
  table-layout: fixed;
  text-align: left; }

._1m5y8PsoQag8sqlUxV4nIm {
  padding: 10px 20px;
  font-weight: 500; }
