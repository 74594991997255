._2_Vk2wrbZdQTfkRv0n1a5y {
  color: #0275d8; }

._2l0bf0cAuHlcDTqqigYD4e {
  color: #5bc0de; }

._2ZcqFEdsgZMOMtaVC0cVER {
  color: #5cb85c; }

._1a7ndqhCRDCkGCLhniSr1G {
  color: #ed1c24; }
