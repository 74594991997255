._3B4tHZI_0c6_KXXG1-tV0u {
  color: #373a3c;
  font-family: "Roboto", sans-serif; }
  ._3B4tHZI_0c6_KXXG1-tV0u .modal-content {
    padding: 20px 30px 35px 23px; }
  ._3B4tHZI_0c6_KXXG1-tV0u .modal-header {
    padding: 0;
    padding-bottom: 15px;
    border-bottom: none; }
    ._3B4tHZI_0c6_KXXG1-tV0u .modal-header > .close {
      position: absolute;
      top: 18px;
      right: 15px;
      font-size: 30px;
      line-height: 0.6; }
  ._3B4tHZI_0c6_KXXG1-tV0u .modal-title {
    font-size: 24px;
    font-weight: 300; }
  ._3B4tHZI_0c6_KXXG1-tV0u .modal-body {
    padding: 0;
    padding-top: 15px; }
