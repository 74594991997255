@media screen and (max-width: 992px) {
  .dVrXXMI6qaRb4yu4m6ZfP {
    display: none; } }

@media screen and (max-width: 992px) {
  ._3_xfwaprL3AzVMVpInL2kS {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: #f8f8f8; } }

._2UfH_oa5ZAce_DhkfKmHMk {
  display: none;
  padding: 0;
  width: 91px;
  height: 91px;
  position: fixed;
  right: 10px;
  bottom: 150px;
  color: #fff;
  background-color: #0275d8;
  border: none;
  border-radius: 50%; }
  ._2UfH_oa5ZAce_DhkfKmHMk > svg {
    width: 51px;
    height: 43px; }
  @media screen and (max-width: 992px) {
    ._2UfH_oa5ZAce_DhkfKmHMk {
      display: inline-flex;
      justify-content: center;
      align-items: center; } }
  @media screen and (max-width: 767px) {
    ._2UfH_oa5ZAce_DhkfKmHMk {
      width: 62px;
      height: 62px;
      right: 5px;
      bottom: 20px; }
      ._2UfH_oa5ZAce_DhkfKmHMk > svg {
        margin: 0 auto;
        -webkit-transform: translateX(-1px);
                transform: translateX(-1px);
        width: 35px;
        height: 31px; } }
