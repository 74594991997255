@media screen and (max-width: 992px) {
  ._1c-Sg6pgZXmObdJMJ00m50 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    min-height: calc(100vh - 150px); } }

._1NOFJvGcFS90pZSSovrEdP {
  position: relative;
  margin-bottom: 20px; }
  @media screen and (max-width: 992px) {
    ._1NOFJvGcFS90pZSSovrEdP {
      display: flex;
      align-items: stretch;
      margin-bottom: 0; } }

@media screen and (max-width: 992px) {
  ._2Anktq7uQ_2j-NK8WY9hVm {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: auto;
    max-height: calc(100vh - 150px) !important; } }

@media screen and (max-width: 992px) {
  ._3PzQWkfDk3w5PuvJ1vGUkH {
    display: flex;
    flex-direction: column-reverse; } }

._3S13nuhMh-xPqnwKZo-EU {
  width: 100%; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    ._3S13nuhMh-xPqnwKZo-EU {
      height: 41px;
      resize: none;
      margin-right: 34px; } }
  @media screen and (max-width: 767px) {
    ._3S13nuhMh-xPqnwKZo-EU {
      height: 44px;
      resize: none;
      border-radius: 30px; } }

._1sriQw9H00R8oEnNtQgH3g {
  margin-bottom: 40px; }
  ._1sriQw9H00R8oEnNtQgH3g > svg {
    display: none;
    width: 15px;
    height: 30px; }
    @media screen and (max-width: 767px) {
      ._1sriQw9H00R8oEnNtQgH3g > svg {
        display: inline-block; } }
  @media screen and (max-width: 992px) {
    ._1sriQw9H00R8oEnNtQgH3g {
      margin-bottom: 0;
      height: 41px;
      min-width: auto; } }
  @media screen and (max-width: 767px) {
    ._1sriQw9H00R8oEnNtQgH3g {
      padding: 0;
      height: 100%;
      width: 100%;
      min-width: auto;
      border-radius: 50%;
      font-size: 0; } }

@media screen and (max-width: 767px) {
  ._2HN-QBjD6hR6V4aNPqJRng {
    width: 44px;
    height: 44px;
    position: absolute;
    top: 0;
    right: 2px; } }

._19w83IyoFI2wAjnPLdgoga {
  display: none; }

@media screen and (min-width: 768px) and (max-width: 992px) {
  ._3NaKDHgpbNYtRvIgVbcUc9 {
    min-width: 127px;
    margin-right: 20px; }
    ._3NaKDHgpbNYtRvIgVbcUc9:last-child {
      margin-right: 0; } }

@media screen and (max-width: 767px) {
  ._3NaKDHgpbNYtRvIgVbcUc9 {
    margin-right: 7px; }
    ._3NaKDHgpbNYtRvIgVbcUc9:last-child {
      margin-right: 0; } }

._2J5uZa9v0OUKM6TtGtCdL8 { }
  @media screen and (max-width: 767px) {
    ._2J5uZa9v0OUKM6TtGtCdL8 {
      flex-grow: 1;
      font-weight: 500;
      text-transform: uppercase; } }

._1vD-Ki_wHuwG-m2sRYJ1ea {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: #f8f8f8; }
  @media screen and (max-width: 992px) {
    ._1vD-Ki_wHuwG-m2sRYJ1ea {
      display: flex;
      justify-content: center; } }
