.Mi6UuiFjk4dmTDWs6mktM {
  padding-top: 8px;
  margin-bottom: 9px;
  color: #131311;
  font-size: 16px; }
  @media screen and (max-width: 992px) {
    .Mi6UuiFjk4dmTDWs6mktM {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff; } }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .Mi6UuiFjk4dmTDWs6mktM {
      padding: 23px 15px 17px;
      font-size: 24px; } }
  @media screen and (max-width: 767px) {
    .Mi6UuiFjk4dmTDWs6mktM {
      padding: 17px 15px;
      font-size: 22px; } }

@media screen and (max-width: 992px) {
  .CEtWHg7HodGTZxZeyE7vA {
    min-height: 100vh; } }

._5m9ESG0n1_wUP0DHyddT {
  color: #c4c4c4;
  display: none;
  padding: 0;
  background: none;
  border: none; }
  ._5m9ESG0n1_wUP0DHyddT > svg {
    width: 21px;
    height: 21px; }
  @media screen and (max-width: 992px) {
    ._5m9ESG0n1_wUP0DHyddT {
      display: inline-block; } }
