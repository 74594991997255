._2HmqpeMSg2zI3wCT0ge0uA {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

._11uOr6nzvwoPJyNk2TG-kB {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.jIb3vpO3AIHZ-xuw07Ky0 {
  margin-left: 8px;
  text-transform: uppercase;
  font-weight: 500; }

.Gu0IlubR0JHTfy6Ch3_r2 {
  margin: 0;
  font-size: 16px;
  color: #131311; }

._19DmRQtT7AW28gCPGEpzPM {
  margin: 0;
  margin-top: 8px;
  color: #9b9b9b;
  font-style: italic;
  font-size: 14px; }
