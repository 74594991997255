.bRTo1kkDvphehLQtEzeU2 {
  width: 1em;
  height: 1em;
  -webkit-animation-name: _2ahgaETwmDcJdP6Zs4YUSN;
          animation-name: _2ahgaETwmDcJdP6Zs4YUSN;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  vertical-align: middle; }

@-webkit-keyframes _2ahgaETwmDcJdP6Zs4YUSN {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

@keyframes _2ahgaETwmDcJdP6Zs4YUSN {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }
