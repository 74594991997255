._1HVPiKLf8ipHtOlkzxRiMD {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center; }

._jkVnaTsNsaOW6hXzoPCK {
  position: relative;
  max-width: 532px;
  max-height: 100vh;
  overflow-y: auto;
  background-color: #fff; }
  @media screen and (max-width: 767px) {
    ._jkVnaTsNsaOW6hXzoPCK {
      max-width: none;
      width: 100%; } }

._1-fW9UtI-XAozFipLgneZZ {
  max-width: 900px; }

._18Oon24nPGS5ooRtg6E0VA {
  padding: 28px 36px 22px 29px; }
  @media screen and (max-width: 767px) {
    ._18Oon24nPGS5ooRtg6E0VA {
      padding: 20px 15px 28px 15px; } }

._1YzND_7LB3ZBbo7Ma8EmMm {
  max-height: calc(100vh - 15px) !important; }
  ._1YzND_7LB3ZBbo7Ma8EmMm > div {
    max-height: 100vh !important; }

._1IBPTBmNLTKrZr4a4aT7uN {
  margin-bottom: 18px; }
  ._1IBPTBmNLTKrZr4a4aT7uN:last-child {
    margin-bottom: 0; }

.KpNj1Z5olxtxvDvICa2Dc {
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 300; }
  .KpNj1Z5olxtxvDvICa2Dc:last-child {
    margin-bottom: 0; }

.LJK4TaBXESbM3SzSIoGK4 {
  margin: 0;
  padding-right: 15px;
  text-align: left;
  color: #131311;
  font-size: 24px;
  font-weight: normal; }

._25Hhbu_KpYJ9WDhQuKY50e {
  text-align: right; }
  ._25Hhbu_KpYJ9WDhQuKY50e > button {
    margin-right: 13px; }
    ._25Hhbu_KpYJ9WDhQuKY50e > button:last-child {
      margin-right: 0; }

._2b5L_NATjIK52B-tF12d28 {
  padding: 0;
  position: absolute;
  top: 15px;
  right: 18px;
  width: 21px;
  height: 21px;
  color: #131311;
  background: none;
  border: none;
  outline: none; }
  ._2b5L_NATjIK52B-tF12d28 > svg {
    width: 21px;
    height: 21px; }
