._2iqoHRfuPITq65WoAmur3A {
  margin: 0; }

._3g5sfGIYOQS7J4QRs8hHOf {
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 5px;
  padding: 4px 14px;
  border: 1px solid #5bc0de;
  background-color: #fff;
  border-radius: 3px;
  font-size: 16px;
  line-height: 1;
  color: #131311; }

._2tahJIr--tXXJ4QcheiQ29 {
  margin-right: 3px;
  text-transform: uppercase;
  font-weight: normal;
  display: inline; }

._1CcZOvBjF68102iHjj8Hza {
  display: inline; }
