._3dpPmKdvitlSFDSjUurnNs {
  background-color: #ded2e1;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.DfJyUd0BulzmPm1Vgi6OU {
  margin: 0 auto;
  display: block;
  width: 110px;
  height: 92px; }

._23HmXWRIoiWWIA40QVR0tq {
  margin-bottom: 30px;
  color: #403843;
  text-align: center;
  font-size: 48px; }
  @media screen and (max-width: 767px) {
    ._23HmXWRIoiWWIA40QVR0tq {
      font-size: 36px; } }

._3itVD7MZWntjMvbmbFlWf7 {
  margin: 0 auto;
  padding: 3px;
  color: #403843;
  font-weight: 300;
  text-align: center;
  font-size: 22px;
  line-height: 30px; }
  @media screen and (max-width: 767px) {
    ._3itVD7MZWntjMvbmbFlWf7 {
      font-size: 20px;
      line-height: 32px; } }

._160rFxaUHmnut9MV7dg6Eb {
  margin-top: 30px;
  text-align: center; }

._3KPYYkgpj_K8pT7xehs65F {
  width: 185px;
  border: 1px solid #403843;
  color: #403843;
  font-weight: 300;
  display: inline-block;
  cursor: pointer;
  border-radius: 10px;
  font-size: 24px;
  background-color: #ded2e1; }
  ._3KPYYkgpj_K8pT7xehs65F:hover, ._3KPYYkgpj_K8pT7xehs65F:focus {
    text-decoration: none;
    opacity: 0.5; }
