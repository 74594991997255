._1b0K8ss_tx13QBzoc2NHQa {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px; }

._2mD1WsBz6PxJe1JSpTcw2K {
  background-color: #5bc0de;
  color: #fff; }

.W3bGudgvC_e0lhZhYMFWi {
  margin-bottom: 5px; }
