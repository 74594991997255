.likL6s3HIJdTOo4ZoO_gW {
  margin-bottom: 40px; }
  @media screen and (max-width: 767px) {
    .likL6s3HIJdTOo4ZoO_gW {
      margin-bottom: 30px; } }

._2hXGtrluywPgjZex1lHWwm {
  margin-bottom: 9px;
  color: #828282;
  font-size: 16px; }
  @media screen and (max-width: 767px) {
    ._2hXGtrluywPgjZex1lHWwm {
      margin-bottom: 6px;
      font-size: 18px; } }

._2vwXvcDQf17vO2A01IxCUy {
  font-weight: 500;
  font-style: italic; }

._1lwpXQQ7abjgR-oYklqiNB {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: #131311;
  font-family: "Roboto", sans-serif;
  font-size: 16px; }
  @media screen and (max-width: 767px) {
    ._1lwpXQQ7abjgR-oYklqiNB {
      font-size: 18px; } }
