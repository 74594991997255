._17fZWP6CaG9xN4_SEqrTty {
  max-width: 1170px;
  margin: 0 auto;
  padding: 15px; }
  ._17fZWP6CaG9xN4_SEqrTty ._24xhoG5Kwf4veDomFFJ5n3 {
    line-height: 21px;
    color: #373a3c; }
  ._17fZWP6CaG9xN4_SEqrTty .Z1Y1oKTA7cGPKxoS5OTq {
    margin-top: 2em; }

.VDOahhBabejC56TuRe25W {
  display: flex;
  justify-content: flex-end; }

.xx9OwngTqYQWXCYDbPWui {
  border: 1px solid #dddddd;
  border-radius: 30px;
  background-color: white;
  padding: 5px 20px;
  color: #3e8acc;
  transition: all ease 0.3s;
  outline: none; }
  .xx9OwngTqYQWXCYDbPWui:hover {
    background-color: gainsboro;
    color: white; }

._3vxi9LmXUqvMcNDRr6XoQf {
  padding-top: 3em; }
  ._3vxi9LmXUqvMcNDRr6XoQf .cAfuP7IR3cvQcJ3HGhiFW {
    text-align: center; }

.Oy-o0YOJoOxP24bL6QkEP {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .Oy-o0YOJoOxP24bL6QkEP .cAfuP7IR3cvQcJ3HGhiFW {
    margin-bottom: 0; }

.cAfuP7IR3cvQcJ3HGhiFW {
  margin: 0;
  margin-bottom: 22px;
  font-size: 24px;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .cAfuP7IR3cvQcJ3HGhiFW {
      display: none; } }
