.teb_7M_fXGNJX_t5nTdd3 {
  width: 100%; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .teb_7M_fXGNJX_t5nTdd3 {
      table-layout: fixed; } }
  .teb_7M_fXGNJX_t5nTdd3 > thead {
    color: #131311;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5; }
    .teb_7M_fXGNJX_t5nTdd3 > thead > tr {
      background-color: rgba(144, 144, 144, 0.06);
      border-bottom: 1px solid #828282;
      height: 36px; }
      .teb_7M_fXGNJX_t5nTdd3 > thead > tr > th {
        padding: 8px 7px 4px 7px;
        vertical-align: middle; }
  .teb_7M_fXGNJX_t5nTdd3 > tbody {
    font-size: 16px;
    line-height: 1.5; }
    .teb_7M_fXGNJX_t5nTdd3 > tbody > tr {
      height: 79px;
      border-bottom: 1px solid #828282; }
      .teb_7M_fXGNJX_t5nTdd3 > tbody > tr:nth-child(even) {
        background-color: rgba(144, 144, 144, 0.06); }
      .teb_7M_fXGNJX_t5nTdd3 > tbody > tr > td {
        padding: 8px 7px 4px 7px;
        vertical-align: middle; }
