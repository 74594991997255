._35br-CnqG1lr1SRH7i9lgn {
  margin-left: 1px;
  margin-right: 1px;
  padding: 7px 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  border-radius: 2px; }
  ._35br-CnqG1lr1SRH7i9lgn::-webkit-input-placeholder {
    color: #9b9b9b; }
  ._35br-CnqG1lr1SRH7i9lgn:-ms-input-placeholder {
    color: #9b9b9b; }
  ._35br-CnqG1lr1SRH7i9lgn::-ms-input-placeholder {
    color: #9b9b9b; }
  ._35br-CnqG1lr1SRH7i9lgn::placeholder {
    color: #9b9b9b; }
