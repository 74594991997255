._1ST40_5B7lrGy7LW4HvS0- {
  margin-right: 8px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  color: #131311; }

._1EgikJv3iylAnNaxaVoIR5 {
  flex-grow: 1;
  margin-right: 22px;
  padding: 6px 9px;
  color: #131311;
  font-size: 16px;
  border: 1px solid #131311;
  border-radius: 3px; }

._36XkjNEztPect_QKOQvA0P {
  margin: 0;
  margin-top: 32px;
  font-size: 18px; }

._EcRDFiXd_msvGqOtYsG2 {
  margin: 0;
  visibility: hidden;
  height: 0; }

._1pQ1pKkCUGb3LQyx3hwjbZ {
  padding: 0;
  border: none;
  outline: none;
  background: none;
  color: #d9534f;
  font-size: 18px; }
  ._1pQ1pKkCUGb3LQyx3hwjbZ[disabled] {
    opacity: 0.5; }

._2AkT-JLHgLxgqXuRo8hvc4 {
  display: flex;
  justify-content: space-between;
  align-items: center; }
