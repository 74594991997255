._29YooyHY6edx5tmK9PTzaH {
  display: flex; }

._1U8JOO5NHqaNmNljKtnMF6 {
  flex: 1;
  padding: 10px;
  outline: none;
  resize: vertical; }

.E50ntIMMsWurp-Fv3qQal {
  width: 75px;
  align-self: center;
  margin-left: 15px; }
