._1OyIb0hRZF741jkcAsHXHz {
  position: relative; }

/* Frozen overlay (legacy) */
._2WaXalROKhNTG544kceFmo, .lxO0PEnJmdGg6KoqoErwh {
  background-color: #ffffff;
  opacity: 0.5; }

.lxO0PEnJmdGg6KoqoErwh {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999; }

._2lgCnE3i19xSiuhigV7-Z- {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-flex;
  width: 40px;
  height: 40px;
  font-size: 40px; }

._3mL_9n0BnBrARDbx_WBWiW {
  width: 30px;
  height: 30px; }

/* Realtime overlay */
.z0G7sd0l1ZMCG1YNhYRYT {
  background-color: #ffffff;
  opacity: 0.5; }

._3uH-u7eldNZ5WZzTryfzDg {
  -webkit-animation: _2DxLMhrhErflAp92PHfquH 1.5s;
          animation: _2DxLMhrhErflAp92PHfquH 1.5s;
  background-color: #5cb85c; }

._3ifEzhn4X1DsvygQ_yc3z3 {
  -webkit-animation: _2DxLMhrhErflAp92PHfquH 1.5s;
          animation: _2DxLMhrhErflAp92PHfquH 1.5s;
  background-color: #f0ad4e; }

.z_l4Zwwz2zwM1KEtXvTHp {
  -webkit-animation: _2DxLMhrhErflAp92PHfquH 1.5s;
          animation: _2DxLMhrhErflAp92PHfquH 1.5s;
  background-color: #d9534f; }

@-webkit-keyframes _2DxLMhrhErflAp92PHfquH {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 0; } }

@keyframes _2DxLMhrhErflAp92PHfquH {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 0; } }

/* Realtime text overlay */
._39MDkXrR2fKHpIScK5d-8l {
  -webkit-animation: _2wq4rn8806mjUl6MGxhQqF 0.6s infinite;
          animation: _2wq4rn8806mjUl6MGxhQqF 0.6s infinite; }

._2j4VyZhsFrbvIfLN8BUPe6 {
  -webkit-animation: _2beGaqAo1ttLF7j-ZmFqHI 0.6s infinite;
          animation: _2beGaqAo1ttLF7j-ZmFqHI 0.6s infinite; }

._2LKTxNRTJ5OWKWcv1wuabC {
  -webkit-animation: TgU8BewnD9_8oojiHyFXU 0.6s infinite;
          animation: TgU8BewnD9_8oojiHyFXU 0.6s infinite; }

@-webkit-keyframes _2wq4rn8806mjUl6MGxhQqF {
  0% {
    color: #ffffff; }
  100% {
    color: #5cb85c; } }

@keyframes _2wq4rn8806mjUl6MGxhQqF {
  0% {
    color: #ffffff; }
  100% {
    color: #5cb85c; } }

@-webkit-keyframes _2beGaqAo1ttLF7j-ZmFqHI {
  0% {
    color: #ffffff; }
  100% {
    color: #f0ad4e; } }

@keyframes _2beGaqAo1ttLF7j-ZmFqHI {
  0% {
    color: #ffffff; }
  100% {
    color: #f0ad4e; } }

@-webkit-keyframes TgU8BewnD9_8oojiHyFXU {
  0% {
    color: #ffffff; }
  100% {
    color: #d9534f; } }

@keyframes TgU8BewnD9_8oojiHyFXU {
  0% {
    color: #ffffff; }
  100% {
    color: #d9534f; } }

/* background text animation */
._2jmpclYNnQfvMlVRVJGQVx {
  -webkit-animation: _31tUVjHE84SSWFdTs-oGVb 1.5s;
          animation: _31tUVjHE84SSWFdTs-oGVb 1.5s; }

._1l7T02Mhk5wrGyQnDMJWj4 {
  -webkit-animation: _1qDosZW80-DIkAP3TAoMHT 1.5s;
          animation: _1qDosZW80-DIkAP3TAoMHT 1.5s; }

._2TUIFE6OCRwyAnkhMB8SF8 {
  -webkit-animation: _3nwAx7RuQpWLazm1LqFGwU 1.5s;
          animation: _3nwAx7RuQpWLazm1LqFGwU 1.5s; }

@-webkit-keyframes _31tUVjHE84SSWFdTs-oGVb {
  0% {
    background-color: rgba(92, 184, 92, 0.2); }
  100% {
    background-color: transparent; } }

@keyframes _31tUVjHE84SSWFdTs-oGVb {
  0% {
    background-color: rgba(92, 184, 92, 0.2); }
  100% {
    background-color: transparent; } }

@-webkit-keyframes _1qDosZW80-DIkAP3TAoMHT {
  0% {
    background-color: rgba(240, 173, 78, 0.2); }
  100% {
    background-color: transparent; } }

@keyframes _1qDosZW80-DIkAP3TAoMHT {
  0% {
    background-color: rgba(240, 173, 78, 0.2); }
  100% {
    background-color: transparent; } }

@-webkit-keyframes _3nwAx7RuQpWLazm1LqFGwU {
  0% {
    background-color: rgba(217, 83, 79, 0.2); }
  100% {
    background-color: transparent; } }

@keyframes _3nwAx7RuQpWLazm1LqFGwU {
  0% {
    background-color: rgba(217, 83, 79, 0.2); }
  100% {
    background-color: transparent; } }

/* color text animation */
._2q_wIIVBo5yTaI6TVhI_15 {
  -webkit-animation: _36pGlO6lrX-sn66eOA7NlO 0.5s infinite;
          animation: _36pGlO6lrX-sn66eOA7NlO 0.5s infinite; }

._37f2lVUPcKpQdvaznZ6Ngi {
  -webkit-animation: _1HRkysalAI6X-N3IF5FUqF 0.5s infinite;
          animation: _1HRkysalAI6X-N3IF5FUqF 0.5s infinite; }

.yeNMehYUkC6QSbZfCh4q7 {
  -webkit-animation: _2NFmlEgnLnc6-vrV_f933N 0.5s infinite;
          animation: _2NFmlEgnLnc6-vrV_f933N 0.5s infinite; }

@-webkit-keyframes _36pGlO6lrX-sn66eOA7NlO {
  0% {
    color: inherit; }
  100% {
    color: rgba(92, 184, 92, 0.8); } }

@keyframes _36pGlO6lrX-sn66eOA7NlO {
  0% {
    color: inherit; }
  100% {
    color: rgba(92, 184, 92, 0.8); } }

@-webkit-keyframes _1HRkysalAI6X-N3IF5FUqF {
  0% {
    color: inherit; }
  100% {
    color: rgba(240, 173, 78, 0.8); } }

@keyframes _1HRkysalAI6X-N3IF5FUqF {
  0% {
    color: inherit; }
  100% {
    color: rgba(240, 173, 78, 0.8); } }

@-webkit-keyframes _2NFmlEgnLnc6-vrV_f933N {
  0% {
    color: inherit; }
  100% {
    color: rgba(217, 83, 79, 0.8); } }

@keyframes _2NFmlEgnLnc6-vrV_f933N {
  0% {
    color: inherit; }
  100% {
    color: rgba(217, 83, 79, 0.8); } }
