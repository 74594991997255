.VktBMAL-rvYSUX_WGoHPq {
  padding-top: 1px;
  padding-left: 7px;
  padding-right: 7px;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  background-color: #ed1c24;
  border-radius: 100px;
  font-size: 12px;
  line-height: 1.75;
  font-weight: 500;
  text-align: center; }
  .VktBMAL-rvYSUX_WGoHPq._3zglrUE91v4iuLy1D69LWg {
    background-color: #5bc0de; }

._1QI_G5Lx4USdhJBUrMoFM9 {
  margin-top: -3em; }
