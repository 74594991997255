._2LB007MGLEikqRem1ZWRUH {
  padding: 12px;
  text-align: center;
  margin-bottom: 10px; }

._1tBuHcjJky9FfanyHgQs0q {
  margin-bottom: 10px; }

.G8d-sgQ_85Wf_eZ5CkuVt {
  padding: 12px 0; }
  .G8d-sgQ_85Wf_eZ5CkuVt > button {
    padding: 0;
    color: #5cb85c;
    outline: none;
    background: none;
    border: none; }
    .G8d-sgQ_85Wf_eZ5CkuVt > button:hover, .G8d-sgQ_85Wf_eZ5CkuVt > button:focus {
      color: #5cb85c; }

._19x5aYLEGBS3Z8scqkZESz {
  margin: 0 0 17px 0;
  padding: 0 20px; }

._2abOeJ4h4O45JyO0i7jTC_ {
  padding: 11px 0;
  border-top: 1px solid #e7e7e7; }
  ._2abOeJ4h4O45JyO0i7jTC_:first-child {
    border-top: 0px; }
