._3OCV8jyYdiNtiycor5GmIw {
  display: block;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    ._3OCV8jyYdiNtiycor5GmIw {
      max-width: 120px; } }
  @media screen and (max-width: 767px) {
    ._3OCV8jyYdiNtiycor5GmIw {
      max-width: 100%; } }

._2dlVOQ8CgIe4O2r0V7WdbP {
  text-decoration: none;
  color: #131311; }

.dDArmSijMMQxrHUWnW72e {
  position: relative;
  display: inline-block;
  padding-left: 40px; }

._3NFg-P93moqXxj_dO6w3nM { }

._1hSgZIf8vP6ILE69yQr0dZ {
  padding: 0;
  background: none;
  border: none;
  font-size: 16px; }
  ._1hSgZIf8vP6ILE69yQr0dZ > svg {
    margin-left: 8px;
    width: 9px;
    height: 7px;
    vertical-align: middle; }

._2d_VBw0kF5z5UFHv0sadYi { }
  ._2d_VBw0kF5z5UFHv0sadYi > svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

._2rZ8N2iDFhdrSYmm9iTdRk,
._2P9jY72Aym8RIYrNgsrJjC {
  max-width: none; }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    ._2rZ8N2iDFhdrSYmm9iTdRk,
    ._2P9jY72Aym8RIYrNgsrJjC {
      max-width: 150px; } }

._2rZ8N2iDFhdrSYmm9iTdRk {
  font-size: 14px;
  line-height: 1.75;
  word-break: break-all; }

.vW0PCwLH9sMdkOj3i9F3i {
  min-width: 110px; }
  @media screen and (max-width: 767px) {
    .vW0PCwLH9sMdkOj3i9F3i {
      position: absolute;
      top: 0;
      right: 0;
      min-width: none; } }

._1OY9RjXC-MvxnPMzRiHDGB {
  font-size: 100%; }
  @media screen and (max-width: 767px) {
    ._1OY9RjXC-MvxnPMzRiHDGB {
      border-radius: 0 !important;
      font-weight: normal !important;
      font-size: 16px;
      text-transform: uppercase; } }

@media screen and (max-width: 767px) {
  ._1luCNKNePriyV6LAmIcNrk {
    display: block; } }

@media screen and (max-width: 767px) {
  ._1luCNKNePriyV6LAmIcNrk > thead {
    display: none; } }

._1luCNKNePriyV6LAmIcNrk > thead > tr > th {
  white-space: nowrap; }
  @media screen and (max-width: 767px) {
    ._1luCNKNePriyV6LAmIcNrk > thead > tr > th {
      white-space: normal; } }

@media screen and (max-width: 767px) {
  ._1luCNKNePriyV6LAmIcNrk > tbody {
    display: block; } }

._1luCNKNePriyV6LAmIcNrk > tbody > tr {
  cursor: pointer; }
  @media screen and (max-width: 767px) {
    ._1luCNKNePriyV6LAmIcNrk > tbody > tr {
      height: auto;
      margin-bottom: 15px;
      padding: 30px 17px;
      position: relative;
      border: 1px solid #979797;
      background: #f8f8f8;
      display: flex;
      flex-direction: column; } }
  ._1luCNKNePriyV6LAmIcNrk > tbody > tr > td {
    text-align: left; }
    @media screen and (max-width: 767px) {
      ._1luCNKNePriyV6LAmIcNrk > tbody > tr > td {
        padding-left: 70px;
        display: block; } }

._2ArbqFlByTN-GRd8fPUtXM {
  text-align: center !important; }
  ._2ArbqFlByTN-GRd8fPUtXM > img {
    width: 30px;
    height: 30px; }
  @media screen and (max-width: 767px) {
    ._2ArbqFlByTN-GRd8fPUtXM {
      padding-left: 0 !important;
      position: absolute; }
      ._2ArbqFlByTN-GRd8fPUtXM > img {
        width: 42px;
        height: 42px; } }

._3VqONjNnMjHR2DQNPNyF-U {
  width: 170px; }
  @media screen and (max-width: 767px) {
    ._3VqONjNnMjHR2DQNPNyF-U {
      width: auto;
      display: flex !important;
      flex-direction: column-reverse;
      align-items: flex-start;
      font-size: 22px;
      color: #131311; } }

._3QpKfpwWw56tUtzSf8rc2y {
  max-width: 100%;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 767px) {
    ._3QpKfpwWw56tUtzSf8rc2y {
      flex-direction: column-reverse;
      align-items: flex-start; } }

.Dn2alMPeHjnqb1QVp85QP {
  display: inline-block;
  margin-right: 3px; }

@media screen and (max-width: 767px) {
  ._2d0yXM-dP6LCfCD_FP9N89 {
    margin-top: -1em !important; } }

@media screen and (min-width: 768px) and (max-width: 992px) {
  .IxU2SiZVDntTTy0DO54Km {
    max-width: 160px; } }

@media screen and (max-width: 767px) {
  .IxU2SiZVDntTTy0DO54Km {
    max-width: none;
    font-size: 18px;
    line-height: 1; } }

@media screen and (max-width: 992px) {
  ._36ZLwahHrekvm-ZSRnnR32 {
    display: none !important; } }

@media screen and (max-width: 767px) {
  ._29KQ5CmGHGl_UrmKoXDWOR {
    display: none !important; } }

@media screen and (max-width: 767px) {
  ._2pIBaQYQCDXR_XtMtcQVXG {
    border-color: #5cb85c !important; } }

@media screen and (max-width: 767px) {
  ._21BTC3Gy3E8K93s_DDfzUh {
    border-color: #d9534f !important; } }

@media screen and (max-width: 767px) {
  ._1tkEOpFSOk7MRIZIGeTH2U {
    border-color: #428bca !important; } }

@media screen and (max-width: 767px) {
  ._6IhJVKAxvzn_2ug-t3BWj {
    border-color: #5bc0de !important; } }
