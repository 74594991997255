._12jF8-YLu7LE_QoLW1wUG6 {
  min-height: 80px; }
  ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li > span,
  ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li > a {
    padding: 8px 15px;
    font-size: 18px;
    color: #0275d8; }
    ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li > span:hover, ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li > span:focus,
    ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li > a:hover,
    ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li > a:focus {
      background-color: #ecf5ff; }
  ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li.active > span,
  ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li.active > a {
    color: #fff;
    background-color: #0275d8; }
    ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li.active > span:hover, ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li.active > span:focus,
    ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li.active > a:hover,
    ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li.active > a:focus {
      background-color: #0275d8; }
  @media screen and (max-width: 767px) {
    ._12jF8-YLu7LE_QoLW1wUG6 .pagination {
      display: flex; }
      ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li {
        flex-grow: 1; }
        ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li > span,
        ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li > a {
          margin-left: -1px;
          width: calc(100% + 1px);
          text-align: center; }
        ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li:first-child > span,
        ._12jF8-YLu7LE_QoLW1wUG6 .pagination > li:first-child > a {
          margin-left: 0; } }
