._3lTT3Kz36pRs6YqamT3DCk {
  margin-bottom: 10px; }
  ._3lTT3Kz36pRs6YqamT3DCk:last-child {
    margin-bottom: 0; }

.f3SBHZum7qJX42P3EfGND {
  padding: 10px;
  width: 100%;
  background-color: #d9534f;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  border-radius: 3px; }

._1X41ONZxxNspdLnylrXQ1p {
  background-color: #5cb85c; }

.P53Axu4s9EiUJSGGmQJYV p:last-child {
  margin: 0; }
